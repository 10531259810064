import dotenv from "dotenv";
dotenv.config();

export default class Configuration {
  static get CONFIG() {
    return {
      node_env: "$VUE_APP_NODE_ENV",
      sentry_key: "$VUE_APP_SENTRY_KEY",
      sentry_project: "$VUE_APP_SENTRY_PROJECT",
      okta_client_id: "$VUE_APP_OKTA_CLIENT_ID",
      okta_base_url: "$VUE_APP_OKTA_BASE_URL",
      okta_redirect_url: "$VUE_APP_OKTA_REDIRECT_URL",
      okta_issuer_url: "$VUE_APP_OKTA_ISSUER_URL",
      sentia_graphql_api_url: "$VUE_APP_SENTIA_GRAPHQL_API_URL",
      sentia_graphql_api_key: "$VUE_APP_SENTIA_GRAPHQL_API_KEY",
      opsgenie_app_url: "$VUE_APP_OPSGENIE_APP_URL",
      iv_combell_url: "$VUE_APP_IV_COMBELL_URL",
      newrelic_app_url: "$VUE_APP_NEWRELIC_APP_URL",
      bucket_name: "$VUE_APP_BUCKET_NAME",
      object_name: "$VUE_APP_OBJECT_NAME"
    };
  }

  static value(name) {
    if (!(name in this.CONFIG)) {
      console.log(`Configuration: There is no key named "${name}"`);
      return;
    }

    const value = this.CONFIG[name];

    if (!value) {
      console.log(`Configuration: Value for "${name}" is not defined`);
      return;
    }

    if (value.startsWith("$VUE_APP_")) {
      // value was not replaced, it seems we are in development.
      // Remove $ and get current value from process.env
      const envName = value.substr(1);
      const envValue = process.env[envName];
      if (envValue) {
        return envValue;
      } else {
        console.log(
          `Configuration: Environment variable "${envName}" is not defined`
        );
      }
    } else {
      // value was already replaced, it seems we are in production.
      return value;
    }
  }
}
