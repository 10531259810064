import gql from "graphql-tag";

const GET_PRE_SIGNED_URL = gql`
  query create_pre_signed_url($bucket_name: String, $object_name: String) {
    create_pre_signed_url(
      bucket_name: $bucket_name
      object_name: $object_name
    ) {
      url
      fields {
        AWSAccessKeyId
        key
        policy
        signature
        x_amz_security_token
      }
    }
  }
`;

export { GET_PRE_SIGNED_URL };
